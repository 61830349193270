const gaDataLayerService = {
  sendEvent: eventData => {
    window?.dataLayer?.push(eventData);
  },
  getDataLayerClickEventData: element =>
    element?.dataset?.layerClickEvent || null,
  addClickEventListener: () => {
    document?.addEventListener('click', event => {
      let dataLayerClickEventData =
        gaDataLayerService.getDataLayerClickEventData(event.target);
      if (!dataLayerClickEventData) {
        let parentElement = event.target.parentNode;
        while (parentElement && !dataLayerClickEventData) {
          dataLayerClickEventData =
            gaDataLayerService.getDataLayerClickEventData(parentElement);
          parentElement = parentElement.parentNode;
        }
      }
      if (dataLayerClickEventData) {
        gaDataLayerService.sendEvent(JSON.parse(dataLayerClickEventData));
      }
      return true;
    });
  },
  init: () => {
    gaDataLayerService.addClickEventListener();
  }
};

module.exports.gaDataLayerService = gaDataLayerService;
