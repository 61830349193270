export const appendInlineScript = (targetElement, script) => {
  const scriptElement = document.createElement('script');
  const inlineScript = document.createTextNode(script);
  scriptElement.appendChild(inlineScript);
  targetElement.appendChild(scriptElement);
};

export const addScript = (src, attributes = {}) => {
  let sources = Array.isArray(src) ? src : [src];
  let sourcesToLoadLater = [];
  const scriptAttributes = typeof attributes === 'object' ? attributes : {};
  const isSync = scriptAttributes.async === false;
  if (isSync) {
    // Use shift to get the first element of the "sources" array and remove it from that array.
    const firstElement = sources.shift();
    sourcesToLoadLater = sources;
    sources = [firstElement];
  }
  sources.forEach(source => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = source;
    if (isSync && sourcesToLoadLater.length > 0) {
      script.onload = () => {
        addScript(sourcesToLoadLater, scriptAttributes);
      };
    }
    if (scriptAttributes.callback && sourcesToLoadLater.length === 0) {
      script.onload = () => {
        scriptAttributes.callback();
      };
    }
    Object.keys(scriptAttributes).forEach(key => {
      if (!['type', 'src', 'parent', 'first', 'callback'].includes(key)) {
        script.setAttribute(key, scriptAttributes[key]);
      }
    });
    const parent = scriptAttributes.parent
      ? document.querySelector(scriptAttributes.parent)
      : document.body;

    if (scriptAttributes.first) {
      parent.insertBefore(script, parent.firstChild);
    } else {
      parent.appendChild(script);
    }
  });
};

export const isScriptAddedToDom = src =>
  !!document.querySelector(`script[src="${src}"]`);

export const getScriptDomElement = src =>
  document.querySelector(`script[src="${src}"]`);

export const isElementEntirelyInViewport = el => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= window.innerHeight &&
    rect.right <= window.innerWidth
  );
};

// const efp = (x, y) => document.elementFromPoint(x, y);

export const isElementVisible = (el, percentVisible = 0) => {
  const rect = el.getBoundingClientRect();
  return !(
    Math.floor(
      100 - ((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100
    ) < percentVisible ||
    Math.floor(100 - ((rect.bottom - window.innerHeight) / rect.height) * 100) <
      percentVisible
  );

  /*   if (
    el.offsetTop < window.innerHeight &&
    el.offsetTop > -el.offsetHeight &&
    el.offsetLeft > -el.offsetWidth &&
    el.offsetLeft < window.innerWidth
  ) {
    return true;
  }
  return false; */

  // const rect = el.getBoundingClientRect();
  /*   const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement.clientHeight;
 */
  // Return false if it's not in the viewport
  /*   if (
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight
  ) {
    return false;
  } */

  // Return true if any of its four corners are visible
  /*   return (
    el.contains(efp(rect.left, rect.top)) ||
    el.contains(efp(rect.right, rect.top)) ||
    el.contains(efp(rect.right, rect.bottom)) ||
    el.contains(efp(rect.left, rect.bottom))
  ); */
};

/*
export const isTopEdgeInViewport = (el, offset = 0) => {
  const rect = el.getBoundingClientRect();
  return rect.top + offset > 0 && rect.top < window.innerHeight;
};
*/

export const isInViewport = el => {
  const rect = el.getBoundingClientRect();
  const vHeight = window.innerHeight || document.documentElement.clientHeight;
  if (rect.top < vHeight && rect.top > -rect.height) {
    return true;
  }
  return false;
};

export const isBottomEdgeInViewport = (el, offset = 0) => {
  const rect = el.getBoundingClientRect();
  return rect.bottom > 0 && rect.bottom - offset < window.innerHeight;
};

/*
export const getElementOffset = element => {
  const de = document.documentElement;
  const box = element.getBoundingClientRect();
  const top = box.top + window.scrollY - de.clientTop;
  const left = box.left + window.scrollY - de.clientLeft;
  return { top, left };
};
*/

export const getWindowScrollingPercentage = () =>
  (window.scrollY * 100) / (document.body.scrollHeight - window.innerHeight);

export const getOverflowClipping = el => {
  const data = {
    top: false,
    right: false,
    bottom: false,
    left: false
  };

  if (el) {
    const overflowsHorizontally = el.scrollWidth > el.clientWidth;
    const overflowsVertically = el.scrollHeight > el.clientHeight;
    data.left = overflowsHorizontally && el.scrollLeft > 0;
    data.right =
      overflowsHorizontally &&
      Math.ceil(Math.abs(el.scrollLeft)) < el.scrollWidth - el.clientWidth;
    data.top = overflowsVertically && el.scrollTop > 0;
    data.bottom =
      overflowsVertically &&
      Math.abs(el.scrollTop) !== el.scrollHeight - el.clientHeight;
  }

  return data;
};

export const smoothScrollToObject = (target, offset = 70) => {
  const scrollToYCoord =
    target.current.getBoundingClientRect().top + window.scrollY - offset;
  window.scrollTo({ top: scrollToYCoord, behavior: 'smooth' });
};
