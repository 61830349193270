import { conf } from '../configService';
import { domainService } from '../domainService';
import { eventService } from '../eventService';

let previousGroups;

export const oneTrustConsentService = {
  getGroups: () =>
    typeof window === 'object' ? window.OnetrustActiveGroups : '',

  // includes() is being correctly applied to a string (String.prototype.includes()).
  // Not to be confused with Array.prototype.includes().
  allowsFunctionalities: () =>
    oneTrustConsentService.getGroups().includes(conf.cmp_group_functionalities),

  allowsTargeting: () =>
    oneTrustConsentService.getGroups().includes(conf.cmp_group_targeting),

  allowsSocialMedia: () =>
    oneTrustConsentService.getGroups().includes(conf.cmp_group_social_media),

  allowsPerformance: () =>
    // Unlike www.* domains, cms-*.ngeo.com domains don't have "Performance" (C0002)
    // cookies enabled, but we need both domains to have the same configuration.
    // We manually set "Performance" to true if running on a cms-*.ngeo.com domain
    // and "Targeting" (C0004) is also enabled.
    oneTrustConsentService.getGroups().includes(conf.cmp_group_performance) ||
    (domainService.isRunningOnCMS() &&
      oneTrustConsentService.allowsTargeting()),

  getCmpSettings: () => {
    const data = {
      service_loaded: false
    };
    if (oneTrustConsentService.getGroups()) {
      data.service_loaded = true;
    }
    data.cmpPerformance = oneTrustConsentService.allowsPerformance();
    data.cmpFunctionalities = oneTrustConsentService.allowsFunctionalities();
    data.cmpTargeting = oneTrustConsentService.allowsTargeting();
    data.cmpSocialMedia = oneTrustConsentService.allowsSocialMedia();

    return data;
  },
  getPlayerData: () => {
    const cmpSettings = oneTrustConsentService.getCmpSettings();
    const data = {
      fng_player_gam_plugin: cmpSettings.cmpTargeting,
      fng_player_overlay_plugin: true,
      fng_player_comscore_plugin: cmpSettings.cmpPerformance,
      fng_player_youbora_plugin: true,
      fng_player_share_video_plugin: cmpSettings.cmpSocialMedia,
      fng_player_subtitles_plugin: cmpSettings.cmpFunctionalities,
      fng_player_related_videos_plugin: true,
      fng_player_ga_plugin: false,
      fng_player_agecheck_plugin: true,
      fng_player_unmute_plugin: true,
      service_loaded: cmpSettings.service_loaded
    };
    if (typeof window === 'object') {
      // Copy values from data to window.
      Object.keys(data).forEach(key => {
        window[key] = data[key];
      });
    }
    return data;
  },
  getCookiePermissions: (cookieType, settings) => {
    switch (cookieType) {
      case 'cmp_group_functionalities':
        return settings.cmpFunctionalities;
      case 'cmp_group_targeting':
        return settings.cmpTargeting;
      case 'cmp_group_social_media':
        return settings.cmpSocialMedia;
      case 'cmp_group_performance':
        return settings.cmpPerformance;
      case 'cmp_group_essential':
      default:
        return true;
    }
  },
  onOneTrustInteractionDone: () => {
    // Reload page if consent changes.
    const currentGroups = oneTrustConsentService.getGroups();
    if (previousGroups && previousGroups !== currentGroups) {
      document.location.reload();
    } else {
      previousGroups = currentGroups;
    }
  },
  listenToChanges: () => {
    eventService.addEventListener(
      'OneTrustInteractionDone',
      oneTrustConsentService.onOneTrustInteractionDone
    );
  }
};
