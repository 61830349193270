const configByLanguage = {
  'pt-br': {
    country: 'br',
    domain: 'www.nationalgeographicbrasil.com',
    html: {
      stage: 'd2g8e43tkaq8j0.cloudfront.net'
    },
    static: {
      prod: 'static.nationalgeographicbrasil.com',
      stage: 'd2w3k52cqa70kn.cloudfront.net'
    },
    search: {
      // prod is always `https://${configData.canonicalDomain}/api-pluto/pluto`
      stage: '460lslh0yj'
    },
    weekStartDay: 0,
    region: 'latam',
    currency: 'brl'
  },
  'es-mx': {
    country: 'mx',
    domain: 'www.nationalgeographicla.com',
    html: {
      stage: 'd10tqlaqrqekdp.cloudfront.net'
    },
    static: {
      prod: 'static.nationalgeographicla.com',
      stage: 'd2yjz1u528xpd7.cloudfront.net'
    },
    search: {
      stage: '71n2i5w11m'
    },
    weekStartDay: 0,
    region: 'latam',
    currency: 'mxn'
  },
  es: {
    country: 'es',
    domain: 'www.nationalgeographic.es',
    html: {
      stage: 'dqycso6ai2tad.cloudfront.net'
    },
    static: {
      prod: 'static.nationalgeographic.es',
      stage: 'd3vn7zoq1wnpn.cloudfront.net'
    },
    search: {
      stage: 'gaskmukif9'
    },
    weekStartDay: 1,
    region: 'emea',
    currency: 'eur'
  },
  fr: {
    country: 'fr',
    domain: 'www.nationalgeographic.fr',
    html: {
      stage: 'd2qijvrij2kjsi.cloudfront.net'
    },
    static: {
      prod: 'static.nationalgeographic.fr',
      stage: 'd3dquktn7q9giq.cloudfront.net'
    },
    search: {
      stage: '9pxqxe9xa8'
    },
    weekStartDay: 1,
    region: 'emea',
    currency: 'eur'
  },
  de: {
    country: 'de',
    domain: 'www.nationalgeographic.de',
    html: {
      stage: 'd1kmowq504utvb.cloudfront.net'
    },
    static: {
      prod: 'static.nationalgeographic.de',
      stage: 'd1repucvdawbk9.cloudfront.net'
    },
    search: {
      stage: 'i3ad0xm8tj'
    },
    weekStartDay: 1,
    region: 'emea',
    currency: 'eur'
  },
  'en-gb': {
    country: 'uk',
    domain: 'www.nationalgeographic.co.uk',
    html: {
      stage: 'd1w5tfnhbo59kb.cloudfront.net'
    },
    static: {
      prod: 'static.nationalgeographic.co.uk',
      stage: 'd3ktbhoxi64ayf.cloudfront.net'
    },
    search: {
      stage: 'pn8duyuo2j'
    },
    weekStartDay: 1,
    region: 'emea',
    currency: 'gbp'
  }
};

const configByCountry = {};
Object.keys(configByLanguage).forEach(langcode => {
  const countryCode = configByLanguage[langcode].country;
  configByCountry[countryCode] = { ...configByLanguage[langcode], langcode };
});

const configByDomain = {};
Object.keys(configByLanguage).forEach(langcode => {
  const { domain } = configByLanguage[langcode];
  configByDomain[domain] = { ...configByLanguage[langcode], langcode };
});

module.exports = { configByLanguage, configByCountry, configByDomain };
