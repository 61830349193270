const { configByLanguage } = require('./configBy');

const getBaseConfig = (hostConfig, rawConfig, langcode) => {
  // Load required raw data.
  const gtmSettings = rawConfig['config_pages/google_tag_manager'];
  const ngMaster = rawConfig['config/natgeo_master'];
  const systemSiteConfig = rawConfig['config/system.site'];
  const systemDateConfig = rawConfig['config/system.date'];
  const tvSettings = rawConfig['config/natgeo_tv'];
  const rawMetadataDefaults = rawConfig['custom/metadata_defaults'];
  const transporterSettings = rawConfig['config/natgeo_transporter'];
  const mpxSettings = rawConfig['config/natgeo_mpx'];
  const videoSettings = rawConfig['config/natgeo_video'];
  const newsletterSettings = rawConfig['config/natgeo_newsletter'];
  const cookieSettings = rawConfig['config_pages/cookie_policy_footer'];
  const ptt = rawConfig['custom/ptt'];

  // Create several variables used across config creation.
  const isStage = ngMaster.is_stage || false;
  const siteCountryCode = configByLanguage[langcode].country;
  const canonicalDomain = configByLanguage[langcode].domain;
  const stageDomain = `cms-stage-${siteCountryCode}.ngeo.com`;

  const config = {
    hash: rawConfig.hash,
    langcode,
    langcodeIso639_1: langcode.substring(0, 2),
    tagManagerContainerID:
      gtmSettings.data.googleTagManager.config[0].containerId,
    isStage,
    siteName: systemSiteConfig.name,
    siteCountryCode,
    localDrupalDevDomain: `${siteCountryCode}.ngeo.local`,
    canonicalDomain,
    stageDomain,
    currentDomain: isStage ? stageDomain : canonicalDomain,
    staticDomain: configByLanguage[langcode].static[isStage ? 'stage' : 'prod'],
    staticCanonicalDomain: configByLanguage[langcode].static.prod,
    s3Bucket: `fng-globalsites-${
      isStage ? 'stage' : 'prod'
    }-html-current-${siteCountryCode}.s3.eu-central-1.amazonaws.com`,
    s3BucketPublicDomain: isStage
      ? configByLanguage[langcode].html.stage
      : canonicalDomain,
    s3BucketId: `fng-globalsites-${
      isStage ? 'stage' : 'prod'
    }-html-current-${siteCountryCode}`,
    searchEndpoint: isStage
      ? `https://${
          configByLanguage[langcode].search.stage
        }.execute-api.eu-central-1.amazonaws.com/default/PLUTO_Search_ST_${siteCountryCode.toUpperCase()}`
      : `https://${canonicalDomain}/api-pluto/pluto`,
    weekStartDay: configByLanguage[langcode].weekStartDay,
    oneTrust: configByLanguage[langcode].oneTrust,
    gamAccountId: isStage ? '21743558653' : '21783347309',
    facebookDomainVerification:
      configByLanguage[langcode].facebookDomainVerification,
    defaultTimeZone: systemDateConfig.timezone.default,

    // NatGeo Master Settings.
    age_verification: ngMaster.age_verification,
    primetime_start_hour: ngMaster.primetime_start_hour,
    primetime_start_minute: ngMaster.primetime_start_minute,
    primetime_end_hour: ngMaster.primetime_end_hour,
    primetime_end_minute: ngMaster.primetime_end_minute,
    hide_newsletter_subscription: ngMaster.hide_newsletter_subscription,
    hide_newsletter_consent: ngMaster.hide_newsletter_consent,
    upcoming_episodes_offset: ngMaster.upcoming_episodes_offset,
    display_full_schedule_button: ngMaster.display_full_schedule_button,
    full_schedule_button_link: ngMaster.full_schedule_button_link,
    sponsoredDefaultAdditionalText: ngMaster.default_sponsored_text || '',
    sponsoredTravellerAdditionalText: ngMaster.traveller_sponsored_text || '',
    ad_refresh_rate: ngMaster.ad_refresh_rate || 0,
    sweepstakes_js_url: ngMaster.sweepstakes_js_url,
    newsletter_custom_link: ngMaster.newsletter_custom_link || null,
    activate_cmp: ngMaster.activate_cmp || false,

    // TV settings.
    tvSettings,

    // MPX.
    MpxVideoPlayerTemplate:
      mpxSettings?.video_player_template ||
      videoSettings?.video_player_template ||
      'ngpgs/default',

    // Newsletter.
    newsletter: newsletterSettings,

    // Cookie Policy.
    cookiePolicy: cookieSettings.data.cookiePolicy.entities[0],

    // Metadata defaults.
    metadataDefaults: {
      fb_app_id: rawMetadataDefaults.fb_app_id,
      article_author: rawMetadataDefaults.article_author,
      twitter_cards_creator: rawMetadataDefaults.twitter_cards_creator,
      twitter_cards_site: rawMetadataDefaults.twitter_cards_site
    },

    transporterSettings,

    ptt
  };

  // Add conditional custom code settings.
  if (ngMaster.active_code_custom) {
    config.active_code_custom = ngMaster.active_code_custom;
    config.bundle_code_custom_css = ngMaster.bundle_code_custom_css;
    config.bundle_code_custom_js = ngMaster.bundle_code_custom_js;
    config.header_code_custom = ngMaster.header_code_custom;
    config.footer_code_custom_html = ngMaster.footer_code_custom_html;
  }

  // Add conditional CMP settings.
  if (config.activate_cmp) {
    config.iab_compliance = ngMaster.iab_compliance || false;
    config.cmp_js_url = ngMaster.cmp_js_url || null;
    config.cmp_js_data_domain_script = hostConfig.isPreview
      ? ngMaster[
          `cmp_js_data_domain_script_cms_domain_${isStage ? 'stage' : 'prod'}`
        ]
      : ngMaster[`cmp_js_data_domain_script_${isStage ? 'stage' : 'prod'}`];
    config.cmp_group_functionalities =
      ngMaster.cmp_group_functionalities || 'fc';
    config.cmp_group_targeting = ngMaster.cmp_group_targeting || 'tg';
    config.cmp_group_social_media = ngMaster.cmp_group_social_media || 'sm';
    config.cmp_group_performance = ngMaster.cmp_group_performance || 'pc';
  }

  return config;
};

const getMenus = rawConfig => {
  const menus = {
    menuGlobalNavigation: rawConfig['menu/natgeo-global-nav'].data.menu.links,
    menuMainNavigation: rawConfig['menu/main'].data.menu.links,
    menuContent: rawConfig['menu/content'].data.menu.links,
    menuSubscribeHeader: rawConfig['menu/subscribe-header'].data.menu.links,
    menuSections: rawConfig['menu/sections'].data.menu.links,
    menuFooter: rawConfig['menu/footer'].data.menu.links,
    menuFooterExplore: rawConfig['menu/explore'].data.menu,
    menuFooterAboutUs: rawConfig['menu/about-us'].data.menu,
    menuFooterSubscribe: rawConfig['menu/subscribe'].data.menu.links,
    menuSocialMedia:
      rawConfig['config_pages/social_media'].data.socialMedia.links[0]
  };

  return menus;
};

const processConfig = (hostConfig, rawConfig, langcode) => {
  return {
    ...hostConfig,
    ...getBaseConfig(hostConfig, rawConfig, langcode),
    menus: getMenus(rawConfig),
    locale: rawConfig['locale/locale']
  };
};

module.exports = { processConfig };
